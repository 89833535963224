$cloudinaryThemeCloudBaseUrl: 'https://res.cloudinary.com/difp9jq5j/image/upload/themes/kultour/';
@import 'styles/helpers/global_variables';

$animationDuration: 300ms;
.navbar {
  min-height: $navHeight;
  padding-top: 1rem;
  padding-bottom: 1rem;
  transition: all $animationDuration linear;
  transition-property: background-color, height, top;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(3px);

  &.bgWhite {
    background-color: white;
    box-shadow: $shadow-nav;
  }

  &.hidden {
    top: -$navHeight !important;
  }
}
